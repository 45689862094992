<template>
  <div class="w-45">
    <div class="text-end my-3 mx-1">
      <v-btn
        :disabled="!checkPerUser"
        class="t-btn--prm"
        @click="checkPerUser ? (visible = 5) : ''"
      >
        <span>
          <i class="fas fa-plus"> </i>&emsp;
          {{ $t('buttons.add') }}
        </span>
      </v-btn>
    </div>

    <v-card class="mx-1 my-1">
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
        }"
        ref="table"
        :itemsPerPage="20"
        :itemsPerPageOptions="[20, 50, 100, 200, 500]"
        :headers="headersTable"
        :items="otherPublicDoclistOtherMaterials"
      >
        <template v-slot:[`item.docTypename`]="{ item }">
          <div v-if="item.docType">
            {{ item.docType.name ? item.docType.name : '' }}
          </div>
        </template>
        <template v-slot:[`item.url`]="{ item }">
          <v-btn
            :style="checkPerUser ? '' : 'background-color: #bdbdbd !important'"
            :disabled="!checkPerUser"
            icon
            medium
            class="file t-btn--prm"
            color="#fff"
          >
            <a
              class="btn-link"
              target="_blank"
              :href="checkPerUser ? item.url : ''"
            >
              <v-icon class="icon-btn"> mdi-file-document-outline </v-icon>
            </a>
          </v-btn>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-btn
            :style="checkPerUser ? '' : 'background-color: #bdbdbd !important'"
            :disabled="!checkPerUser"
            @click="checkPerUser ? ((idDel = item.id), (dialog = true)) : ''"
            icon
            class="t-btn--red-dark"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>

    <!-- dialog -->
    <v-row justify="center" class="mt-5">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="t-btn--red-dark" text @click="dialog = false">
              いいえ
            </v-btn>
            <v-btn
              :disabled="!checkPerUser"
              class="file t-btn--prm"
              style="color: white"
              text
              @click="checkPerUser ? (del(), (dialog = false)) : ''"
            >
              はい
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <upload-other-materials-dialog
      v-if="checkPerUser"
      :visible="visible === 5"
      @close="visible = -1"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UploadOtherMaterialsDialog from './UploadOtherMaterialsDialog.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  components: { UploadOtherMaterialsDialog, Table },
  name: 'VacationLife',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dialog: false,
      idDel: null,
      visible: -1,
      headersTable: [
        {
          text: 'カテゴリ',
          value: 'docTypename',
          align: 'left',
          sortable: false,
        },
        {
          text: '表示ファイル名',
          value: 'fileName',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('VacationLife.viewFile'),
          value: 'url',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('buttons.delete'),
          value: 'delete',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['otherPublicDoclistOtherMaterials']),
  },
  mounted() {
    this.getOtherPublicDoclistOtherMaterials();
  },
  methods: {
    ...mapActions([
      'getOtherPublicDoclistOtherMaterials',
      'deleteOtherPublicDocOtherMaterials',
    ]),

    del() {
      this.deleteOtherPublicDocOtherMaterials(this.idDel);
    },
  },
};
</script>

<style scoped lang="scss">
.w-45 {
  max-width: 45%;
}
.text-end .v-btn {
  height: 32px !important;
  min-width: 83px !important;
}
.v-btn.v-size--default {
  font-size: 12px !important;
  i {
    width: 12px;
    height: 12px;
  }
}

::v-deep {
  .v-tab--active:hover {
    background-color: #d0d0d0 !important;
    color: #1b1b1b !important;
  }
  .v-tab .v-tab--active {
    background-color: #3d3939 !important;
  }
  .theme--light.v-data-table {
    color: #232222;
  }
  .v-data-footer__select {
    color: #000 !important;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 12px !important;
    color: #757575;
  }
  .v-data-footer__pagination {
    display: none;
  }
  .v-data-footer {
    font-size: 11px !important;
  }
  .v-data-footer__select .v-select__selections .v-select__selection--comma,
  .v-application .v-input * {
    font-size: 11px !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    white-space: nowrap !important;
  }
  .v-input__append-inner {
    .v-icon__svg {
      color: #000000;
    }
  }
}
.btn-link {
  text-decoration: none;
  color: inherit;
}
</style>

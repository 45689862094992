<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog">
      <v-form ref="form">
        <h3 class="mb-4">アップロード</h3>
        <div class="w-80 px-8 mt-6">
          <v-select
            :items="itemsData.yearList"
            :label="labelyear"
            dense
            :rules="rules.required"
            v-model="finishData.year"
            item-text="name"
            item-value="id"
          >
          </v-select>
        </div>
        <div class="w-80 px-8 mt-6">
          <v-combobox
            :items="itemsData.monthList"
            :label="labelmonth"
            :rules="rules.rulesMonth"
            v-model="finishData.month"
            dense
          >
          </v-combobox>
        </div>
        <v-file-input
          :label="labelCamera"
          filled
          :rules="rules.required"
          :messages="[$messages.fileLimit({type: 'PDF'})]"
          @change="getFile($event)"
          @click="onInputClick($event)"
          prepend-icon="mdi-camera"
          class="mt-6"
        ></v-file-input>
        <div class="d-flex justify-end">
          <div class="d-flex">
            <v-btn large class="t-btn--red-dark" @click="closeDialog">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t('contract.manager.close') }}
            </v-btn>
            <v-btn large class="t-btn--prm ml-3" @click="save">
              {{ $t('contract.manager.save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { GET_URL_IMG_LIST_VACATIONLIFE } from '@/api/graphql/dashboard/webPageManage/webDocument/vacationLife/index.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  name: 'VacationLifeUploadDialog',
  data() {
    return {
      itemsData: {
        yearList: [],
        monthList: []
      },
      finishData: {
        year: null,
        month: null,
        url: null,
      },
      mainImageUrl: '',
      labelyear: '年度',
      labelmonth: '月号',
      labelCamera: 'バケーションライフアップロード',
      rules: {
        required: [value => !!value || '必須項目です。'],
        rulesMonth: [
          v => !!v || '必須項目です。',
          v =>
            this.funcRulesMonth(v) ||
            '同じ年月にファイルをアップロードできません。',
        ],
      },
    };
  },
  props: {
    visible: Boolean,
  },
  created () {
    // Create year and month list
    const yearNow = parseInt(new Date().getFullYear());
    const yearStart = yearNow + 10;
    const yearEnd = yearNow - 10;
    for (let i = yearEnd; i <= yearStart; i++) {
      this.itemsData.yearList.push({ id: i, name: i + '年' });
    }

    for (let i = 1; i <= 12; i++) {
      this.itemsData.monthList.push(`${i}${this.labelmonth}`);
    }
  },
  computed: {
    ...mapGetters(['vacationLifeYearMonthList', 'yearVacationLife', 'monthVacationLife']),
    // passwordConfirmationRule(value) {
    //   return () => (this.vacationLifeYearMonthList.includes(value)) || 'Password must match'
    // },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    handlErrorView,    
    getCopyErrorTextView,
    funcRulesMonth(val) {
      const result = this.vacationLifeYearMonthList.filter(
        e => e.year === this.finishData.year,
      );
      if (result.length <= 0) {
        return true;
      } else {
        const resultMonth = result.filter(e => e.month === val);
        if (resultMonth.length <= 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    ...mapActions(['createVacationlife']),
    ...mapMutations([
      'setCopyErrorText',
      'setFileUrlVacationLife',
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    getFile(e) {
      if (e) {
        this.file = e;
        this.getInfoFile(e.type, e.size, e.name);
      }
    },
    onInputClick(event) {
      event.target.value = '';
    },
    getInfoFile(types, length, name) {
      const variables = {
          type: 'public-doc/vacationLife',
          contentType: types,
          contentLength: length,
          fileName: name
        }
      this.$apollo
      .mutate({
        mutation: gql`${GET_URL_IMG_LIST_VACATIONLIFE}`,
        variables: variables,
      })
      .then(data => {
        this.uploadUrl = data.data.generateUploadUrl.uploadUrl;
        this.fileUrl = data.data.generateUploadUrl.fileUrl;
         axios.put(
          this.uploadUrl,
          this.file,
          {
            headers: {
              'Content-Type': this.file.type,
            },
          },
        );
        this.finishData.url = this.fileUrl;
      })
      .catch(async error => {
        this.setCopyErrorText(this.getCopyErrorTextView(GET_URL_IMG_LIST_VACATIONLIFE, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        await axios.put(
          this.uploadUrl,
          this.file,
          {
            headers: {
              'Content-Type': this.file.type,
            },
          },
        );
        this.createVacationlife(this.finishData).then((result) => {
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
h3 {
  font-size: 28px;
  color: #212121;
}
::v-deep {
  .v-dialog {
    width: 397px;
    max-width: 100%;
    background-color: white;
    padding: 23px 20px 17px;
    border-radius: 9px;
    .v-label {
      font-size: 8px;
      color: #000 !important;
      font-weight: 600;
    }
  }
  .v-select__selection--comma {
    font-size: 12px !important;
    font-weight: 500;
  }
  .v-text-field__slot {
    .v-label {
      font-size: 13px;
      color: #606060 !important;
      font-weight: 500;
    }
  }
  .w-80 {
    width: 80%;
  }
  .v-btn__content {
    font-size: 12px;
  }
  .theme--light.v-select .v-select__selections {
    color: #000 !important;
    font-weight: 600;
  }
  .v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 50px;
    width: 314px;
    background-color: #f0f0f0;
  }
  .t-btn--red-dark {
    height: 32px !important;
    min-width: 72px !important;
    .v-icon {
      font-size: 12px !important;
    }
  }
  .t-btn--prm {
    height: 32px !important;
    min-width: 68px !important;
  }
  .v-icon__svg {
    color: #333333;
  }
  .v-icon.v-icon.v-icon--link {
    color: #757575;
  }
}
</style>

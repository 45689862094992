<template>
  <page-manage-base>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <div class="mx-5 my-5">
        <v-tabs class="ml-5 bg-sliver" v-model="tab">
          <v-tab v-for="(item, i) in TabName" :key="i" :class="{'text-custom':i===0}">
            {{ item.text }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="mx-5 my-5">
          <v-tab-item v-for="(item, i) in TabName" :key="i">
            <vacation-life v-if="tab === 0" />
            <other-materials v-if="tab === 1" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </page-manage-base>
</template>

<script>
import PageManageBase from '../PageManageBase.vue';
import OtherMaterials from './otherMaterials/OtherMaterials.vue';
import VacationLife from './vacationLife/VacationLife.vue';
export default {
  components: { PageManageBase, VacationLife, OtherMaterials },
  name: 'WebDocumentList',
  data() {
    return {
      tab: 0,
      TabName: [
        {
          id: 1,
          text: 'バケーションライフ',
        },
        {
          id: 3,
          text: 'その他資料',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.v-tab {
  background-color: #f7f7f7 !important;
  color: #4d4d4d !important;
  font-weight: 600;
}
.v-tab,
.v-tab--active {
  font-size: 14px !important;
}
.v-tab--active {
  color: #4d4d4d !important;
  background-color: #d0d0d0 !important;
}
.v-tab--active:hover {
  background-color: #d0d0d0 !important;
}
.text-custom {
  color: #1B1B1B!important;
}
::v-deep {
  .bg-sliver .v-tabs-slider-wrapper {
    display: none !important;
  }
  .v-slide-group__content {
    div:nth-child(2) {
      * {
        color: #1b1b1b !important;
        font-weight: bold !important;
      }
    }
  }

}
</style>
<template>
  <div class="vacation">
    <div class="text-end my-3 mx-1">
      <v-btn
        :disabled="!checkPerUser"
        class="t-btn--prm"
        @click="checkPerUser ? (visible = 3) : ''"
      >
        <span>
          <i class="fas fa-plus"> </i>&emsp;
          {{ $t('buttons.add') }}
        </span>
      </v-btn>
    </div>
    <v-card class="mx-1 mt-1">
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
        }"
        ref="table"
        :itemsPerPage="20"
        :itemsPerPageOptions="[20, 50, 100, 200, 500]"
        :headers="headersTable"
        :items="vacationLifeListVacationLife"
      >
        <template v-slot:[`item.url`]="{ item }">
          <v-btn
            icon
            medium
            :style="checkPerUser ? '' : 'background-color: #bdbdbd !important'"
            :disabled="!checkPerUser"
            class="file t-btn--prm"
            color="#fff"
          >
            <a class="btn-link" :href="item.url" target="_blank">
              <v-icon class="icon-btn"> mdi-file-document-outline </v-icon>
            </a>
          </v-btn>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-btn
            :style="checkPerUser ? '' : 'background-color: #bdbdbd !important'"
            :disabled="!checkPerUser"
            icon
            class="t-btn--red-dark"
            @click="checkPerUser ? ((idDel = item.id), (dialog = true)) : ''"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>

    <!-- dialog -->
    <v-row justify="center" class="mt-5">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="t-btn--red-dark" text @click="dialog = false">
              いいえ
            </v-btn>
            <v-btn
              :disabled="!checkPerUser"
              class="file t-btn--prm"
              style="color: white"
              text
              @click="checkPerUser ? (del(), (dialog = false)) : ''"
            >
              はい
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <vacation-life-upload-dialog
      v-if="checkPerUser"
      :visible="visible === 3"
      @close="visible = -1"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VacationLifeUploadDialog from './VacationLifeUploadDialog.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  components: { VacationLifeUploadDialog, Table },
  name: 'VacationLife',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dialog: false,
      idDel: null,
      visible: -1,
      headersTable: [
        {
          text: this.$t('commons.year'),
          value: 'year',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('commons.month'),
          value: 'month',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('VacationLife.viewFile'),
          value: 'url',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('buttons.delete'),
          value: 'delete',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['vacationLifeListVacationLife']),
  },
  created() {
    this.getVacationLifeListVacationLife();
  },
  methods: {
    ...mapActions(['getVacationLifeListVacationLife', 'deleteVacationlife']),

    del() {
      this.deleteVacationlife(this.idDel);
    },
  },
};
</script>

<style scoped lang="scss">
.vacation {
  max-width: 420px;
}
.text-end .v-btn {
  height: 32px !important;
  min-width: 83px !important;
}
.v-btn.v-size--default {
  font-size: 12px !important;
  i {
    width: 12px;
    height: 12px;
  }
}
.v-application--is-ltr .v-data-footer__pagination {
  margin: 0 !important;
}
.v-data-footer__select .v-select {
  margin-left: 27px !important;
}

::v-deep {
  .v-tab--active:hover {
    background-color: #d0d0d0 !important;
    color: #1b1b1b !important;
  }
  .v-tab .v-tab--active {
    background-color: #3d3939 !important;
  }
  .theme--light.v-data-table {
    color: #232222;
  }
  .v-data-footer__select {
    color: #000 !important;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 12px !important;
    color: #757575;
  }
  .v-data-footer__pagination {
    display: none;
  }
  .v-input__append-inner {
    .v-icon__svg {
      color: #000000;
    }
  }
  .v-data-footer__select .v-select__selections .v-select__selection--comma,
  .v-application .v-input *,
  .v-data-footer {
    font-size: 11px !important;
  }
}
.btn-link {
  text-decoration: none;
  color: inherit;
}
</style>

<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="resetForm">
      <v-form ref="form">
        <h3 class="mb-4">アップロード</h3>
        <div class="w-80 px-8 mt-6">
          <label for="">カテゴリ名</label>
          <v-combobox
          :items="getOtherPublicDocTypeList.map((item) => item.name)"
          item-text="name"
          item-value="id"
          :rules="rules.required"
          dense
          v-model="getDocType"
          > </v-combobox>
        </div>
        <div class="w-80 px-8 mt-6">
          <label for="">表示ファイル名</label>
          <v-text-field :rules="rules.required" v-model="getFileName" dense></v-text-field>
        </div>
        <v-file-input
          :label="labelCamera"
          filled
          :rules="rules.required"
          :messages="[$messages.fileLimit({type: 'PDF'})]"
          prepend-icon="mdi-camera"
          @change="getFile($event)"
          @click="onInputClick($event)"
          class="mt-6"
        ></v-file-input>
        <div class="d-flex justify-end">
          <div class="d-flex">
            <v-btn large class="t-btn--red-dark" @click="closeDialog">
              <v-icon class="mr-2">mdi-close</v-icon>
              {{ $t('contract.manager.close') }}
            </v-btn>
            <v-btn large class="t-btn--prm ml-3" @click="save">
              {{ $t('contract.manager.save') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { GET_URL_IMG_OTHER_MATERIALS } from '@/api/graphql/dashboard/webPageManage/webDocument/otherMaterials/index.js';
import axios from 'axios'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import gql from 'graphql-tag'

export default {
  name: 'UploadOtherMaterialsDialog',
  data() {
    return {
      file: null,
      uploadUrl: '',
      fileUrl: '',
      labelCamera: 'その他資料アップロード',
      rules:{
        required: [(value) => !!value || "必須項目です。"],
      },
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapGetters([
      'categoryName',
      'docTypeOtherMaterials',
      'fileNameOtherMaterials',
      'enumCampaignGroupListApplicant',
      'getOtherPublicDocTypeList'
    ]),
    getDocType:{
      get(){
        return this.docTypeOtherMaterials
      },
      set(value){
        this.setDocTypeOtherMaterials(value)
      }
    },
    getFileName:{
      get(){
        return this.fileNameOtherMaterials
      },
      set(value){
        this.setFileNameOtherMaterials(value)
      }
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  mounted(){
    this.getEnumCampaignGroupListApplicant();
    this.publicDocTypeList()
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'getUrlImgOtherMaterials',
      'createOtherPublicDocOtherMaterials',
      'getOtherPublicDoclistOtherMaterials',
      'getEnumCampaignGroupListApplicant',
      'publicDocTypeList'
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setFileUrlOtherMaterials',
      'setDocTypeOtherMaterials',
      'setFileNameOtherMaterials',
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
      'setOtherPublicDocTypeList'
    ]),
    resetForm() {
      this.$refs.form.reset()
    },
    getFile(event) {
      if (event) {
        this.file = event
        this.getInfoFile(event.type, event.size, event.name)
      }
    },
    onInputClick(event) {
      event.target.value = ''
    },
    async save() {
      if(this.$refs.form.validate()){
        await axios.put(
          this.uploadUrl,
           this.file,
          {
            headers: {
              'Content-Type': this.file.type,
            },
          },
        );
        this.createOtherPublicDocOtherMaterials()
        this.resetForm()
        this.$emit('close')
      }
    },
    getInfoFile(type, length, name) {
      const variables = {
            type: 'public-doc/otherPublicDoc',
            contentType: type,
            contentLength: length,
            fileName: name
          }
      this.$apollo
        .mutate({
          mutation: gql`${GET_URL_IMG_OTHER_MATERIALS}`,
          variables: variables,
        })
        .then((data) => {
          this.uploadUrl = data.data.generateUploadUrl.uploadUrl;
          this.fileUrl = data.data.generateUploadUrl.fileUrl;
          this.setFileUrlOtherMaterials(this.fileUrl)
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(GET_URL_IMG_OTHER_MATERIALS, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        });
    },
    closeDialog() {
      this.resetForm()
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
h3 {
  font-size: 28px;
  color: #212121;
}
label {
  font-size: 8px;
  color: #000 !important;
  font-weight: 600;
}
::v-deep {
  .v-dialog {
    width: 397px;
    max-width: 100%;
    background-color: white;
    padding: 23px 20px 17px;
    border-radius: 9px;
    .v-label {
      font-size: 8px;
      color: #000 !important;
      font-weight: 600;
    }
  }
  .v-select__selection--comma {
    font-size: 12px !important;
  }
  .v-text-field--filled {
    .v-label {
      font-size: 13px;
      color: #606060 !important;
      font-weight: 500;
    }
  }

  .w-80 {
    width: 80%;
    .v-text-field__slot {
      input {
        font-size: 12px !important;
      }
    }
  }
  .v-btn__content {
    font-size: 12px;
  }
  .t-btn--red-dark {
    height: 32px !important;
    min-width: 72px !important;
    .v-icon {
      font-size: 12px !important;
    }
  }
  .t-btn--prm {
    height: 32px !important;
    min-width: 68px !important;
  }

  .v-icon__svg {
    color: #333333;
  }
  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: #f0f0f0;
  }
  .v-icon.v-icon.v-icon--link {
    color: #757575;
  }
}
</style>

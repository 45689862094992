var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-45"},[_c('div',{staticClass:"text-end my-3 mx-1"},[_c('v-btn',{staticClass:"t-btn--prm",attrs:{"disabled":!_vm.checkPerUser},on:{"click":function($event){_vm.checkPerUser ? (_vm.visible = 5) : ''}}},[_c('span',[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('buttons.add'))+" ")])])],1),_c('v-card',{staticClass:"mx-1 my-1"},[_c('Table',{ref:"table",attrs:{"attr":{
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
      },"itemsPerPage":20,"itemsPerPageOptions":[20, 50, 100, 200, 500],"headers":_vm.headersTable,"items":_vm.otherPublicDoclistOtherMaterials},scopedSlots:_vm._u([{key:"item.docTypename",fn:function(ref){
      var item = ref.item;
return [(item.docType)?_c('div',[_vm._v(" "+_vm._s(item.docType.name ? item.docType.name : '')+" ")]):_vm._e()]}},{key:"item.url",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"file t-btn--prm",style:(_vm.checkPerUser ? '' : 'background-color: #bdbdbd !important'),attrs:{"disabled":!_vm.checkPerUser,"icon":"","medium":"","color":"#fff"}},[_c('a',{staticClass:"btn-link",attrs:{"target":"_blank","href":_vm.checkPerUser ? item.url : ''}},[_c('v-icon',{staticClass:"icon-btn"},[_vm._v(" mdi-file-document-outline ")])],1)])]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--red-dark",style:(_vm.checkPerUser ? '' : 'background-color: #bdbdbd !important'),attrs:{"disabled":!_vm.checkPerUser,"icon":""},on:{"click":function($event){_vm.checkPerUser ? ((_vm.idDel = item.id), (_vm.dialog = true)) : ''}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 削除しますか？ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"t-btn--red-dark",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" いいえ ")]),_c('v-btn',{staticClass:"file t-btn--prm",staticStyle:{"color":"white"},attrs:{"disabled":!_vm.checkPerUser,"text":""},on:{"click":function($event){_vm.checkPerUser ? (_vm.del(), (_vm.dialog = false)) : ''}}},[_vm._v(" はい ")])],1)],1)],1)],1),(_vm.checkPerUser)?_c('upload-other-materials-dialog',{attrs:{"visible":_vm.visible === 5},on:{"close":function($event){_vm.visible = -1}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }